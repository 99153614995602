.graph-page {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.multi-graph-page {
    overflow: auto;
}

.graph-page .page-body {
    display: flex;
    flex-direction: column;
}

.add-graph {
    border-color: blue;
    border-radius: 2px;
    background-color: #cccccc;
    padding: 5px;
}

.add-graph .ant-form-inline .ant-form-item-with-help {
    margin-bottom: 0px;
}

.graph-list > * {
    margin: 10px 0px;
}

.graph-list :first-child.chart-container {
    margin: 0px 0px;
}

.graph-list {
    padding-top: 1px;
    overflow: auto;
}

.chart-container {
    flex: 1;
}

.flex-input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.flex-input-group > *:not(:first-child) {
    margin-left: 10px;
}
    