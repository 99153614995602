.map-page-content {
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden;
}

.map-container {
    background-color: #333;
    flex: 1;
    display: flex;
    overflow: hidden;
}

.sidebar-cont {
    flex: 0 0 0px;
    position: relative;
}

.pane {
    width: 0px;
    height: calc(100% - 50px);
    display: block;
    position: absolute;
}

.sidebar {
    display: block;
    width: 300px;
    height: 100%;
    left: 0;
    position: relative;
    background-color: #eee;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    transition-property: -webkit-transform,transform,opacity;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.0,0.0,0.2,1);
}

.sidebar-visible {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
   
}
    
.sidebar-collapsed {
    box-shadow: none;
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
}

.sidebar-toggle-button-container {
    position: absolute;
    z-index: -1;
    top: 8px;
    left: 100%;
    display: block;
}

.sidebar-toggle-button {
    width: 23px;
    height: 48px;
    cursor: pointer;
    background: rgba(255,255,255,0.8) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAUCAQAAAAXDMSnAAAAi0lEQVR4AX3JQcqBURQG4O/+9WNG30D3vOfSDTuQsgcZyBakZANSzMVMBme3zsBI5/VMn4ZKLP5ki1E4tYejWpilxVUtzOEUD68odYmXR5BJNp/4zllXD2phllYvamHmirsayUkfJ5ruHzueTldC08kcT5YOY9xYujqQM03XKXuaLmEtNF1e1Nz89gbL+0do6OEwRwAAAABJRU5ErkJggg==) 7px center/7px 10px no-repeat;
    border-left: 1px solid #D4D4D4;
    box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
    border: 0;
    border-radius: 0;
    font: inherit;
    list-style: none;
    margin: 0;
    overflow: visible;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

.sidebar-toggle-button:active {
    outline: 0;
}

.sidebar-collapsed .sidebar-toggle-button {
    transform: scaleX(-1);
    background-color: #fff;
}

.sidebar-scrollable {
    overflow: auto;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.sidebar-content {
    overflow: auto;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.sidebar-inner {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.sidebar-inner .inner-content {
    background-color: white;
    flex: 1 1;
}

.padded {
    padding: 15px;
}

.sidebar-loading {
    padding: 15px;
    background-color: white;
    flex: 1;
}

.search-container {
    padding: 15px;
    background-color: white;
}

.sensor-select-container {
    flex: 0 0;
    padding: 15px;
}

.ant-tabs,
.ant-tabs-content,
.ant-tabs-tabpane-active,
.sidebar-inner {
    overflow: hidden;
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.ant-tabs-bar {
    flex: 0 0;
    margin: 0 0 0 0 !important;
    padding-left: 5px;
}

@media only screen and (max-width: 600px) {
    .pane {
        display: none;
    }

    .pane .sidebar {
        width: 0px;
    }
}
