.container {
    min-height: 100vh;
    width: 100%;
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-container {
    margin-top: 100px;
    width: 25rem;
    padding: 1rem;
    background-color:#eeeeee;
    border-radius: 0.5rem;
}

.login-container .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-container .login-form > span,
.login-container .login-form > .btn {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

.login-container .btn {
    width: 100%;
}

.login-container .login-title {
    color: rgba(0, 0, 0, 0.3);
    margin-bottom: 1em;
}

@media only screen and (max-width: 600px) {
    .container {
        background-color:#eeeeee;
    }

    .login-container {
        margin-top: 20vh;
        width: 100%;
        background-color:#eeeeee;
        border-radius: 0.0rem;
    }
}

@media (prefers-color-scheme: dark) {
    /*.login-container {
        background-color: rgb(84, 86, 92);
    }

    .login-container .login-title {
        color: rgb(235, 235, 236);
    }*/
}